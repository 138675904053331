import React from "react";
import { BrowserRouter as Router, Route, Routes, } from 'react-router-dom';
import Home from "./pages/Home";
import ConsultaDermatologia from "./pages/ConsultaDermatologia";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ConsultaDermatologia />} />
        <Route path="/consulta-dermatologia" element={<ConsultaDermatologia />} />
        <Route path="/consulta-dermatologica" element={<ConsultaDermatologia />} />

      </Routes>
  </Router>
  );
}

export default App;
