import React from 'react'
import { Link } from 'react-router-dom'
import Menu from './Menu'
import MobileMenu from './MobileMenu'

export default function Header({ scroll, isSearch, handleSearch, isMobileMenu, handleMobileMenu }) {
    return (
        <>
            <header className="main-header header-style-two">
                <div className="header-lower">
                    <div className="main-box">
                        <div className="logo-box">
                            <div className="logo"><Link href="/consulta-dermatologia"><img src="/images/logo.png" alt="Logo"/></Link></div>
                        </div>

                        <div className="nav-outer">
                            {/* <nav className="nav main-menu">
                                <Menu/>
                            </nav> */}
                            <div className="mobile-nav-toggler" onClick={handleMobileMenu}>
                                <span className="icon lnr-icon-bars"></span>
                            </div>
                        </div>

                        {/* <div className="outer-box">
                            <div className="divider"></div>
                            <div className="mobile-nav-toggler" onClick={handleMobileMenu}><span className="icon fa-thin fa-bars-staggered fa-rotate-180"></span></div>
                        </div> */}
                    </div>
                </div>
                {/* Mobile Menu  */}
                <div className="mobile-menu">
                    <div className="menu-backdrop" onClick={handleMobileMenu} />
                    <nav className="menu-box">
                        <div className="upper-box">
                            <div className="nav-logo"><Link href="/consulta-dermatologia"><img src="/images/logo-2.png" alt=""/></Link></div>
                            <div className="close-btn" onClick={handleMobileMenu}><i className="icon fa fa-times" /></div>
                        </div>
                        <MobileMenu />
                        <ul className="contact-list-one">
                            <li>
                                <div className="contact-info-box">
                                    <i className="icon lnr-icon-phone-handset" />
                                    <span className="title">Llama ahora</span>
                                    <Link href="/tel:++525586567090">+52 55 8656-7090 </Link>
                                </div>
                            </li>
                            <li>
                                <div className="contact-info-box">
                                    <span className="icon lnr-icon-clock" />
                                    Lun - Sab 9:00am - 6:00pm, Domingo - CERRADO
                                </div>
                            </li>
                        </ul>
                        <ul className="social-links">
                            <li><Link href="https://www.facebook.com/paudermatologa"><i className="fab fa-facebook-f" /></Link></li>
                            <li><Link href="https://www.instagram.com/skintopia_mx/"><i className="fab fa-instagram" /></Link></li>
                            <li><Link href="https://www.tiktok.com/@dermapaucorona"><i className="fab fa-tiktok" /></Link></li>
                        </ul>
                    </nav>
                </div>
                {/* Header Search */}
                <div className="search-popup">
                    <span className="search-back-drop" onClick={handleSearch} />
                    <button className="close-search" onClick={handleSearch}><span className="fa fa-times" /></button>
                    <div className="search-inner">
                        <form method="post" action="">
                            <div className="form-group">
                                <input type="search" name="search-field" placeholder="Search..." required />
                                <button type="submit"><i className="fa fa-search" /></button>
                            </div>
                        </form>
                    </div>
                </div>
                {/* End Header Search */}
                {/* Sticky Header  */}
                <div className={`sticky-header ${scroll ? "fixed-header animated slideInDown" : ""}`}>
                    <div className="auto-container">
                        <div className="inner-container">
                            {/*Logo*/}
                            <div className="logo">
                                <Link href="/consulta-dermatologia"><img src="/images/logo.png" alt="" /></Link>
                            </div>
                            {/*Right Col*/}
                            <div className="nav-outer">
                                {/* Main Menu */}
                                <nav className="main-menu">
                                    <div className="navbar-collapse show collapse clearfix">
                                        <Menu />
                                    </div>
                                </nav>{/* Main Menu End*/}
                                {/*Mobile Navigation Toggler*/}
                                <div className="mobile-nav-toggler" onClick={handleMobileMenu}><span className="icon lnr-icon-bars" /></div>
                            </div>
                        </div>
                    </div>
                </div>{/* End Sticky Menu */}
            </header >

        </>
    );
};
