import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Video = () => {
    const [isOpen, setOpen] = useState(false)
    return (
        <>
        <section className="video-section" id='contacto'>
            <div className="bg bg-image" style={{ backgroundImage: 'url(/images/background/bg-video-1.png)' }}></div>
            <div className="auto-container">
                <div className="row">
                <div className="col-lg-12">
                    <div className="sec-title mb-0">
                    <h2 className="words-slide-up text-split" style={{paddingBottom:'20px', color:'#fff'}}>Agenda ahora y recibe atención dermatológica confiable y profesional para tu piel</h2>
                        <a href="https://wa.link/wol5fb" target='_blank' className="theme-btn btn-style-one" style={{background:'#40E15D', marginBottom:'10px'}}><span className="btn-title"> AGENDA POR WHATSAPP</span></a>
                        <a href="tel:+525586567090" target='_blank' className="theme-btn btn-style-one" style={{marginLeft:'20px'}}><span className="btn-title"> LLÁMANOS</span></a>
                    </div>
                </div>
                {/* <div className="col-lg-6">
                    <div className="outer-box">
                    <h4>Watch Now</h4>
                    <a onClick={() => setOpen(true)} className="play-now">
                        <i className="icon fas fa-play p-0" aria-hidden="true"></i>
                    </a>
                    </div>
                </div> */}
                </div>
            </div>
        </section>
        
        </>
    );
};

export default Video