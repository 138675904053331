import React from 'react'
import { Link } from 'react-router-dom';

const Blog = () => {
    return (
        <>
        <section className='blog-section-two' id='blog' style={{paddingTop:'0px'}}>
            <div className="auto-container">
                <div className="sec-title text-center">
                <figure className="image"><img src="/images/icons/icon1.png" alt="Image"/></figure>
                <h2 className="words-slide-up text-split">Qué incluye tu Consulta Dermatológica</h2>
                </div>
                <div className="row">
                <div className="blog-block col-lg-4 col-md-6">
                    <div className="inner-box">
                    <div className="image-box">
                        <figure className="image">
                            <img src="/images/resource/Dermatoscopia.jpg" alt="Image"/>
                            <img src="/images/resource/Dermatoscopia.jpg" alt="Image"/>
                        </figure>
                    </div>
                    <div className="content-box">
                        <ul className="post-meta" style={{paddingLeft:'0px'}}>
                        <li className="categories" style={{color:'#8800ff'}}>Valoración médica con Dermatoscopio y Diagnóstico</li>
                        </ul>
                        <div className="text animate-4">Es un <span style={{fontWeight:'700', color:'#000'}}>examen dermatológico detallado</span> y no invasivo que nos permite observar la piel a un nivel más profundo. Utilizando el <span style={{fontWeight:'700', color:'#000'}}>dermatoscopio</span>, podemos detectar y evaluar con precisión diversas condiciones cutáneas, incluyendo lesiones, lunares y signos tempranos de cáncer de piel. Este método nos ayuda a ofrecer diagnósticos más exactos y a desarrollar tratamientos personalizados para ti 😊.</div>
                    </div>
                    </div>
                </div>

                <div className="blog-block col-lg-4 col-md-6">
                    <div className="inner-box">
                    <div className="image-box">
                        <figure className="image">
                            <img src="/images/resource/2.jpg" alt="Image"/>
                            <img src="/images/resource/2.jpg" alt="Image"/>
                        </figure>
                    </div>
                    <div className="content-box">
                        <ul className="post-meta" style={{paddingLeft:'0px'}}>
                        <li className="categories" style={{color:'#8800ff'}}>Entrega de Receta Médica con explicación de tratamiento y cuidados en casa</li>
                        </ul>
                        <div className="text animate-4">
                        Incluye una detallada explicación del <span style={{fontWeight:'700', color:'#000'}}>tratamiento y los cuidados necesarios en casa.</span> Nuestros dermatólogos expertos se aseguran de que comprendas cada aspecto de tu plan de tratamiento, proporcionando instrucciones claras y precisas sobre cómo aplicar los medicamentos, qué productos utilizar y qué hábitos adoptar para maximizar los resultados. Esta <span style={{fontWeight:'700', color:'#000'}}>atención personalizada</span> garantiza que sigas el tratamiento correctamente y obtengas el mejor cuidado para tu piel, promoviendo una recuperación ✅ y un mantenimiento óptimo.
                        </div>
                    </div>
                    </div>
                </div>

                <div className="blog-block col-lg-4 col-md-6">
                    <div className="inner-box">
                    <div className="image-box">
                        <figure className="image">
                            <img src="/images/resource/aparatologia-facial.jpg" alt="Image"/>
                            <img src="/images/resource/aparatologia-facial.jpg" alt="Image"/>
                        </figure>
                    </div>
                    <div className="content-box">
                        <ul className="post-meta" style={{paddingLeft:'0px'}}>
                        <li className="categories" style={{color:'#8800ff'}}>🎁¡De cortesía! Mini sesión de aparatología antiinflamatoria (según tu caso)</li>
                        </ul>
                        <div className="text animate-4">
                        Dependiendo de tu diagnóstico, te daremos de cortesía una mini sesión de <span style={{fontWeight:'700', color:'#000'}}>aparatología facial antiinflamatoria</span> avanzada para reducir enrojecimientos y calmar áreas irritadas. Esta atención adicional personalizada está pensada para potenciar los resultados de tu tratamiento, y para que conozcas <span style={{fontWeight:'700', color:'#000'}}>la experiencia Skintopia de cuidado integral y efectivo.</span> <br/><br/>
                        <span style={{fontWeight:'700', color:'#000'}}>Agenda ahora y recibe atención dermatológica confiable y profesional para tu piel</span>

                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
        </>
    );
};

export default Blog