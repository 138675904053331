import React, {useEffect} from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

const swiperOptions = {
	modules: [Autoplay, Pagination, Navigation],
	slidesPerView: 1,
    pagination: true,
	autoplay: {
		delay: 25000,
		disableOnInteraction: false,
	},
	loop: true
};
const Testimonial = () => {

    useEffect(() => {
        // Añade el script de Elfsight cuando se monta el componente
        const script = document.createElement('script');
        script.src = "https://static.elfsight.com/platform/platform.js";
        script.async = true;
        document.body.appendChild(script);

        // Captura y suprime el warning de ResizeObserver
        const resizeObserverErrorHandler = (e) => {
            if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
                e.stopImmediatePropagation();
            }
        };
        window.addEventListener('error', resizeObserverErrorHandler);

        // Limpia el script y el event listener al desmontar el componente
        return () => {
            document.body.removeChild(script);
            window.removeEventListener('error', resizeObserverErrorHandler);
        };
    }, []);

	return (
	<>
    <section className="testimonial-section testimonial-style-two" id='testimonios' style={{paddingBottom:'60px'}}>
        <div className="instagram1-7 bounce-x"></div>
        <div className="testimonial-pattrn1-2 bounce-y"></div>
		<div className="auto-container">
			<div className="sec-title text-center">
				<figure className="image"><img src="/images/icons/icon1.png" alt="Image"/></figure>
				<span className="sub-title">Testimonios</span>
				<h2 className="words-slide-up text-split">¿Qué dicen de nosotros?</h2>
			</div>
            <div className="elfsight-app-53439400-57d6-4507-90da-5a8b4d990903" data-elfsight-app-lazy></div>

        </div>
    </section>
	</>
	);
};
export default Testimonial
