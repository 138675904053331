import React, {useEffect} from 'react'
import { Helmet } from 'react-helmet'
import Header from '../components/Header'
import Banner3 from '../components/Banner3'
import Services from '../components/Services'
import Testimonial from '../components/Testimonial'
import Blog from '../components/Blog'
import Video from '../components/Video'
import Footer from '../components/Footer'

function ConsultaDermatologia() {

    return (
        <>
            {/* Google Tag Manager en el head */}
            <Helmet>
                <script>
                    {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-N8BRMDL');`}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16577254697"></script>
                <script>
                    {`window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'AW-16577254697');`}
                </script>
            </Helmet>

            {/* Google Tag Manager en el body */}
            <noscript>
                <iframe
                    src="https://www.googletagmanager.com/ns.html?id=GTM-N8BRMDL"
                    height="0"
                    width="0"
                    style={{ display: 'none', visibility: 'hidden' }}
                ></iframe>
            </noscript>

            {/* Contenido de la página */}
            <Header />
            <Banner3 />
            <Testimonial />
            <Services />
            <Blog />
            <Video />
            <Footer />
        </>
    );
}


export default ConsultaDermatologia