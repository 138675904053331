import {React, useState} from 'react'
import { Link } from 'react-router-dom'

function MobileMenu() {
    const [isActive, setIsActive] = useState({
        status: false,
        key: "",
    })

    const handleClick = (key) => {
        if (isActive.key === key) {
            setIsActive({
                status: false,
            })
        } else {
            setIsActive({
                status: true,
                key,
            })
        }
    }

    return (
        <>
            <ul className="navigation clearfix">
                {/*Keep This Empty / Menu will come through Javascript*/}
                <li><Link to="/consulta-dermatologia">Inicio</Link></li>
                <li><Link to="/consulta-dermatologia">Antes y Después </Link></li>
                <li><Link to="/consulta-dermatologia">Reseñas</Link></li>  
                <li><Link to="/consulta-dermatologia">Qué incluye la consulta </Link></li>    
                <li><Link to="/consulta-dermatologia">Agenda Cita</Link></li>                    
                
            </ul>
        </>
    )
}

export default MobileMenu