import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <>
        <footer className="main-footer footer-style-two">
		<div className="bg bg-image" style={{ backgroundImage: 'url(/images/background/bg-footer1.jpg)' }}></div>
		<div className="widgets-section">
			<div className="footer1-1 bounce-x"></div>
			<div className="footer-pattrn1 bounce-y"></div>
			<div className="auto-container">
				<div className="row">

					<div className="footer-column col-xl-3  col-lg-6 col-md-6 col-sm-6">
						<div className="footer-widget about-widget">
							<div className="logo"><Link href="/"><img src="/images/logo-2.png" alt=""/></Link></div>
							<div className="text">
								Uxmal 381, Narvarte Poniente, Benito Juárez, 03020, Ciudad de México
							</div>
							<div className="text">
								Aviso de publicidad
								<br/>
								2309142002A00020
							</div>
						</div>
					</div>

					<div className="footer-column col-xl-3  col-lg-6 col-md-6 col-sm-6">
						<div className="footer-widget links-widget">
							<h3 className="widget-title">Horarios </h3>
							<div className="text">
								Lunes a Viernes
								<br/>
								11:00 – 20:00 h
							</div>
							<div className="text">
							Sábados | 9:00 – 15:00 h
							</div>
						</div>
					</div>

					<div className="footer-column col-xl-3  col-lg-6 col-md-6 col-sm-6">
						<div className="footer-widget timetable-widget">
							<h3 className="widget-title">Citas Dermatología</h3>
                            <div className="text">
                                Whatsapp: 55 5415 8687
								<br/>
								Llámanos
                                <br/>
								55 8656 7090<br/>
								55 5162 1247
							</div>
						</div>
					</div>

					<div className="footer-column col-xl-3  col-lg-6 col-md-6 col-sm-6">
						<div className="footer-widget contacts-widget">
							<a href="https://www.doctoralia.com.mx/clinicas/skintopia" target='_blank' className="theme-btn btn-style-one"><span className="btn-title"> Quiero Cita </span></a>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className="footer-bottom">
			<div className="auto-container">
				<div className="inner-container">
					<div className="copyright-text">&copy; Skintopia 2020-2024 |  <Link href="/">Derechos reservados</Link></div>
				</div>
			</div>
		</div>
        </footer>
        </>
    )
}

export default Footer