import React from 'react'

const Services = () => {
    return (
        <>
        <section className="services-section-two" id='resultados' style={{paddingBottom:'100px', overflowX:'hidden'}}>
            <div className="bg bg-image-four" style={{ backgroundImage: 'url(/images/background/bg-service1.jpg)' }}></div>
            <div className="leaf3-pattrn1 bounce-x"></div>
            <div className="about2-8-pattrn2 bounce-y"></div>
            <div className="auto-container">
                <div className="outer-box">
                    <div className="sec-title text-center">
                        <figure className="image"><img src="/images/icons/icon1.png" alt="Image"/></figure>
                        {/* <span className="sub-title">Lo que hacemos</span> */}
                        <h2 className="words-slide-up text-split">Nuestros Resultados</h2>
                    </div>
                    <div className="row">
                        <div className="service-block-two col-lg-6 col-md-6">
                            <div className="inner-box">
                                <div className="bg bg-image" style={{ backgroundImage: 'url(/images/resource/service2-3.png)' }}></div>
                                <figure className="image-two mb-0"><img src="/images/resource/tratamiento-acne-antes-skintopia.jpg" alt="Image"/></figure>
                                <div className="content-box-hover">
                                    <div className="image-box">
                                        <figure className="image mb-0"><img src="/images/resource/tratamiento-acne-despues-skintopia.jpg" alt="Image"/></figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="service-block-two col-lg-6 col-md-6">
                            <div className="inner-box">
                                <div className="bg bg-image" style={{ backgroundImage: 'url(/images/resource/service2-3.png)' }}></div>
                                <figure className="image-two mb-0"><img src="/images/resource/tratamiento-rosacea-antes-skintopia.jpg" alt="Image"/></figure>
                                <div className="content-box-hover">
                                    <div className="image-box">
                                        <figure className="image mb-0"><img src="/images/resource/tratamiento-rosacea-despues-skintopia.jpg" alt="Image"/></figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
        </>
    );
};

export default Services